export const getGeoInfo = () => {
    return fetch('https://ipapi.co/json/')
    .then((response) => response.json())
    .catch((error) => console.log(error))
}
export const getGeoInfo1 = () => {
    return fetch('https://ipapi.is/json/')
    .then((response) => response.json())
    .then((data) => data.location)
    .catch((error) => console.log(error))
}
export const getGeoInfo2 = () => {
    return fetch('https://api.ipapi.is/')
    .then((response) => response.json())
    .then((data) => data.location)
    .catch((error) => console.log(error))
}